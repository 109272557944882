import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import { fetchDataAndProceed, fetchDataForToolkit } from "../helpers"
import initialState from "./initialState"
import {
  GET_WEBSITE_INFORMATION,
  GET_TENANT_INFO_FROM_WEBSITE,
  CREATE_NEW_TENANT,
  GET_TENANT_KNOWLEDGE,
  GET_TENANT_STATS,
  UPDATE_TENANT_INFO,
  ADD_MEMBERS,
  GET_MEMBERS,
  DELETE_MEMBERS,
  TRANSFER_TENANT,
  GET_TENANT_CHATLOG_TIME_SERIES_ADMIN,
  GET_TENANT_CHATLOG_TIME_SERIES,
  GET_BOT_APPEARANCE,
  UPDATE_BOT_APPEARANCE,
  INVITE_MEMBERS,
  SUBMIT_USER_FEEDBACK,
  TRAIN_TENANT_BOT,
  GET_BOT_TRAINING_STATUS,
  GET_ROUTE_BASED_NOTIFICATION_LIST,
  UPDATE_ROUTE_BASED_NOTIFICATION,
  DELETE_ROUTE_BASED_NOTIFICATION,
  CREATE_ROUTE_BASED_NOTIFICATION,
  LIVE_CHAT_ENABLED,
  GET_TENANT_INFO_DOCUMENT_LIST,
  ADD_TENANT_INFO_DOCUMENT,
  DELETE_TENANT_INFO_DOCUMENT,
  GET_URL_LIST_FROM_WEBSITE_TRAINING,
  GET_USER_FEEDBACK_DATA,
  GET_TENANT_QUOTA,
  UPDATE_TENANT_QUOTA,
  LIVE_CHAT_CONFIG,
  GET_QUOTA_LIST,
  VALIDATE_BYOK,
  LIST_INVITED_MEMBER,
  DELETE_INVITED_MEMBER,
  UPDATE_MEMBERS,
  UPDATE_INVITATION,
  SYNC_SMART_CHATBOT,
  GET_TENANT_STORE_DATA,
  INTEGRATE_WO_COMMERCE,
  SYNC_CATALOG,
  INTEGRATE_CATALOG,
  WOO_COMMERCE_VERIFY,
  GET_SYNC_INFO,
  INTEGRATE_SHOPIFY,
  GET_TENANT_RESOURCE_URL_STATUS,
  GET_ALL_ECOM_PRODUCTS,
  UPDATE_DYNAMIC_LEAD_STATUS,
  GET_TENANT_WORD_CLOUD_STATS,
  GET_DYNAMIC_FEEDBACK_FORM_TEMPLATE,
  POST_FEEDBACK_FORM_DATA,
  GET_LCS_STATS,
  GET_COUNTRY_STATS,
  GET_ENGAGEMENT_STATS,
  GET_LANGUAGES_STATS,
  GET_CHANNELS_STATS,
  GET_LEADS_STATS,
  GET_FEEDBACKS_STATS,
  GET_CONVERSATION_STATS,
  GET_ECOM_PRODUCT_STATUS,
  SHOPIFY_VERIFY,
  APPEND_CATALOG,
  DELETE_CATALOG,
  UPLOAD_MESSAGE_MEDIA_IMAGE,
} from "./api"
import { METHOD_TYPES } from "../../configs/constants"

const overviewSlice = createSlice({
  name: "overview",
  initialState,
  reducers: {
    updateOverviewState(state, action) {
      return { ...state, ...action.payload }
    },
    updateTenantInfoState(state, action) {
      return {
        ...state,
        tenantInfo: {
          ...state.tenantInfo,
          ...action.payload,
        },
      }
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(getTenantKnowledge.pending, (state) => {
      state.tenantLoading = true
    })
    .addCase(getTenantKnowledge.fulfilled, (state, { payload }) => {
      state.tenantLoading = false
      if (payload.data) state.tenantInfo = payload.data
    })
      .addCase(getTenantStats.pending, (state) => {
        state.statsLoading = true
      })
      .addCase(getTenantStats.fulfilled, (state, { payload }) => {
        state.statsLoading = false
        if (payload.data) {
          let stats = {}
          payload.data.forEach(item => {
            stats[item.type] = item
          })
          state.stats = stats
        }
      })
      .addCase(getTenantStats.rejected, (state, { payload }) => {
        state.statsLoading = true
      })
      .addCase(getTenantInfoDocumentList.fulfilled, (state, { payload }) => {
        if (payload.data) state.uploadedTenantInfoDocumentList = payload.data
      })
      .addCase(addTenantInfoDocument.fulfilled, (state, { payload }) => {
        if (payload.data)
          state.uploadedTenantInfoDocumentList = [
            ...state.uploadedTenantInfoDocumentList,
            payload.data,
          ]
      })
      .addCase(deleteTenantInfoDocument.fulfilled, (state, { payload }) => {
        if (payload.data)
          state.uploadedTenantInfoDocumentList =
            state.uploadedTenantInfoDocumentList.filter(
              (item) => item !== payload.data
            )
      })
      .addCase(getBotAppearance.fulfilled, (state, { payload }) => {
        state.botAppearance = {
          ...payload.data,
          triggerSize: payload.data?.triggerSize || "55px",
        }
      })
      .addCase(updateTenantInfo.fulfilled, (state, { payload }) => {
        if (payload.data) state.tenantInfo = payload.data
        if(payload.data.language !== state.botAppearance.language) state.botAppearance = {
          ...state.botAppearance,
          language : payload.data.language
        }
      })
      .addCase(getRouteBasedNotificationList.pending, (state) => {
        state.notificationListLoading = true
        state.notificationListLoadError = false
      })
      .addCase(
        getRouteBasedNotificationList.fulfilled,
        (state, { payload }) => {
          state.notificationListLoading = false
          state.notificationListLoadError = false
          if (payload.data) state.routeBasedNotificationsList = payload.data
        }
      )
      .addCase(getRouteBasedNotificationList.rejected, (state) => {
        state.notificationListLoadError = true
        state.notificationListLoading = false
      })
      .addCase(createRouteBasedNotification.fulfilled, (state, { payload }) => {
        if (payload.data)
          state.routeBasedNotificationsList = [
            ...state.routeBasedNotificationsList,
            payload.data,
          ]
      })
      .addCase(
        updateRouteBasedRoutification.fulfilled,
        (state, { payload }) => {
          if (payload.data) {
            const index = state.routeBasedNotificationsList.findIndex(
              (item) => (item._id = payload.data._id)
            )
            if (index > -1)
              state.routeBasedNotificationsList = [
                ...state.routeBasedNotificationsList.slice(0, index),
                payload.data,
                ...state.routeBasedNotificationsList.slice(index + 1),
              ]
          }
        }
      )
      .addCase(deleteRouteBasedNotification.fulfilled, (state, { payload }) => {
        if (payload.data && payload.data.notificationId) {
          const index = state.routeBasedNotificationsList.findIndex(
            (item) => (item._id = payload.data.notificationId)
          )
          if (index > -1)
            state.routeBasedNotificationsList = [
              ...state.routeBasedNotificationsList.slice(0, index),
              ...state.routeBasedNotificationsList.slice(index + 1),
            ]
        }
      })
      .addCase(LiveChatEnabled.fulfilled, (state, { payload }) => {
        if (payload.data && payload.data.liveChatConfig) {
          if (state?.tenantInfo?.featureControl?.liveChat) {
            state.tenantInfo.featureControl.liveChat =
              payload.data.liveChatConfig
          } else {
            state.tenantInfo.featureControl = {}
            state.tenantInfo.featureControl.liveChat =
              payload.data.liveChatConfig
          }
        }
      })
      .addCase(getTenantQuota.fulfilled, (state, { payload }) => {
        if (payload.data) state.tenantQuota = {
          ...state.tenantQuota,
          ...payload.data
        }
      })
      .addCase(updateTenantQuota.fulfilled, (state, { payload, meta }) => {
        // if feature is not passed then it is autoRenewal 
        // for autoRenewal only update the autoRenewal object
        const { feature } = meta.arg;
        if (feature == null && payload.data?.autoRenewal) {
          state.tenantQuota.autoRenewal = {...payload.data.autoRenewal};
        } else if (payload.data) {
          state.tenantQuota = {...payload.data};
        }
      })
      .addCase(getLiveChatConfigurations.fulfilled, (state, { payload }) => {
        if (payload.data) state.liveChatConfigurations = payload.data
      })
      .addCase(updateLiveChatConfigurations.fulfilled, (state, { payload }) => {
        if (payload.data) state.liveChatConfigurations = payload.data
      })
      .addCase(getQuotaList.fulfilled, (state, { payload }) => {
        if (payload.data) state.quotaList = payload.data
      })
      .addCase(getInvitedMemberList.fulfilled, (state,{payload})=>{
        if(payload.data) state.memberInvitations = payload.data
      })
      .addCase(getTenantStoreData.fulfilled, (state, { payload }) => {
        if (payload.data) state.eCommerceOnboarding = {
          ...state.eCommerceOnboarding,
          storeInfo: payload.data
        }
      })
      .addCase(getEcommerceSyncInfo.fulfilled, (state, { payload }) => {
        if (payload.data) state.eCommerceOnboarding = {
          ...state.eCommerceOnboarding,
          storeInfo: {
            ...state.eCommerceOnboarding.storeInfo,
            ...payload.data
          }
        }
      })
      .addCase(getAllEComProducts.fulfilled, (state, { payload }) => {
        if (payload.data) state.eCommerceProducts = payload.data || []
      })
      .addCase(updateDynamicLeadStatus.fulfilled, (state, { payload }) => {
        const newStatus = payload.data?.status
        if (payload.data) {
          state.tenantInfo = {
            ...state.tenantInfo,
            isDynamicLeadEnabled: newStatus
          }
        }
      })
      .addCase(getDynamicFeedbackFormTemplate.fulfilled, (state, { payload }) => {
        if (payload.data) state.dynamicFeedbackFormTemplate = payload.data
      })
  },
})

export const getTenantKnowledge = createAsyncThunk(
  "tenant/getTenantKnowledge",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_TENANT_KNOWLEDGE.replace(":tenantId", data.tenantId),
        data,
      },
      helpers
    )
)

export const getTenantStats = createAsyncThunk(
  "tenant/getTenantStats",
  async (data = {}, helpers) =>  Promise.all([
        fetchDataForToolkit({ url: GET_LCS_STATS, data, loader: false }, helpers),
        fetchDataForToolkit({ url: GET_COUNTRY_STATS, data, loader: false }, helpers),
        fetchDataForToolkit({ url: GET_LANGUAGES_STATS, data, loader: false }, helpers),
        fetchDataForToolkit({ url: GET_CHANNELS_STATS, data, loader: false }, helpers),
        fetchDataForToolkit({ url: GET_ENGAGEMENT_STATS, data, loader: false }, helpers),
        fetchDataForToolkit({ url: GET_LEADS_STATS, data, loader: false }, helpers),
        fetchDataForToolkit({ url: GET_FEEDBACKS_STATS, data, loader: false }, helpers),
        fetchDataForToolkit({ url: GET_CONVERSATION_STATS, data, loader: false }, helpers),
      ]).then((response)=>{
        return {
          status: "success",
          data: response.map(res => res.data)
        }
      }).catch((err)=>{
        throw err;
      })
)

export const getTenantInfoFromWebsite = createAsyncThunk(
  "tenant/getTenantInfoFromWebsite",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_TENANT_INFO_FROM_WEBSITE,
        data,
        loader: false,
        modalLoader: true,
      },
      helpers
    )
)

export const getInformationFromWebsite = createAsyncThunk(
  "tenant/getInformationFromWebsite",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_WEBSITE_INFORMATION,
        data,
        loader: false,
        modalLoader: true,
      },
      helpers
    )
)

export const getTenantInfoDocumentList = createAsyncThunk(
  "tenant/getTenantInfoDocumentList",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_TENANT_INFO_DOCUMENT_LIST.replace(":tenantId", data.tenantId),
        data,
      },
      helpers
    )
)

export const addTenantInfoDocument = createAsyncThunk(
  "tenant/addTenantInfoDocument",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: ADD_TENANT_INFO_DOCUMENT.replaceAll(
          ":tenantId",
          data.tenantId
        ).replace(":tenantCreatedByFrontend", data.tenantCreatedByFrontend),
        method: METHOD_TYPES.POST,
        contentType: "multipart/form-data",
        data: data.payload,
      },
      helpers
    )
)

export const deleteTenantInfoDocument = createAsyncThunk(
  "tenant/deleteTenantInfoDocument",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: DELETE_TENANT_INFO_DOCUMENT.replace(":tenantId", data.tenantId),
        method: METHOD_TYPES.DELETE,
        data,
      },
      helpers
    )
)

export const getUrlListFromWebsiteTraining = createAsyncThunk(
  "tenant/getUrlListFromWebsiteTraining",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_URL_LIST_FROM_WEBSITE_TRAINING,
        data,
        loader: false,
        axiosArgs: { signal: helpers.signal },
      },
      helpers
    )
)

export const trainTenantBot = createAsyncThunk(
  "tenant/trainTenantBot",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: TRAIN_TENANT_BOT.replaceAll(":tenantId", data.tenantId),
        data,
        loaderText: "Training",
      },
      helpers
    )
)

export const getBotTrainingStatus = createAsyncThunk(
  "tenant/getBotTrainingStatus",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_BOT_TRAINING_STATUS,
        data,
        loader: false,
        axiosArgs: { signal: helpers.signal },
      },
      helpers
    )
)

export const getUserFeedBackData = createAsyncThunk(
  "tenant/getUserFeedBackData",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_USER_FEEDBACK_DATA,
        data,
        loader: false,
      },
      helpers
    )
)

export const submitUserFeedback = createAsyncThunk(
  "tenant/submitUserFeedback",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: SUBMIT_USER_FEEDBACK,
        data,
        method: METHOD_TYPES.POST,
        loader: false,
        modalLoader: true,
      },
      helpers
    )
)

export const createNewTenant = createAsyncThunk(
  "tenant/createNewTenant",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: CREATE_NEW_TENANT,
        data,
        method: METHOD_TYPES.POST,
        loaderText: "Creating New Bot",
      },
      helpers
    )
)

export const updateTenantInfo = createAsyncThunk(
  "tenant/updateTenantInfo",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: UPDATE_TENANT_INFO.replace(":tenantId", data.tenantId),
        data,
        method: METHOD_TYPES.PUT,
        loaderText: "Updating",
      },
      helpers
    )
)

export const syncSmartChatbot = createAsyncThunk(
  "tenant/syncSmartChatbot",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: SYNC_SMART_CHATBOT.replace(":platform", data.platform),
        data: data,
        loader:false,
        timeout: 60000
      },
      helpers
    )
)

export const syncCatalogBot = createAsyncThunk(
  "tenant/syncCatalogBot",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: SYNC_CATALOG.replace(":tenantId",data.tenantId),
        method: METHOD_TYPES.POST,
        contentType: "multipart/form-data",
        data: data.payload,
        loader: false,
        timeout: 60000
      },
      helpers
    )
)

export const appendCatalogBotProducts = createAsyncThunk(
  "tenant/appendCatalogBotProducts",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: APPEND_CATALOG.replace(":tenantId",data.tenantId),
        method: METHOD_TYPES.POST,
        contentType: "multipart/form-data",
        data: data.payload,
        loader: true,
        loaderText: "Appending products from your store",
        timeout: 60000
      },
      helpers
    )
)

export const deleteCatalogBotProducts = createAsyncThunk(
  "tenant/deleteCatalogBotProducts",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: DELETE_CATALOG.replace(":tenantId",data.tenantId),
        method: METHOD_TYPES.POST,
        contentType: "multipart/form-data",
        data: data.payload,
        loader: true,
        loaderText: "Deleting products from your store",
        timeout: 60000
      },
      helpers
    )
)


//=================================== review require ========================================

export const getGraphData = (data) => (dispatch, getState) => {
  fetchDataAndProceed(
    { dispatch, getState },
    {
      url: getState().pageDetails.user?.isAdmin
        ? GET_TENANT_CHATLOG_TIME_SERIES_ADMIN
        : GET_TENANT_CHATLOG_TIME_SERIES,
      method: METHOD_TYPES.GET,
      loader: false,
      data,
    },
    (err, res) => {
      if (res && !err) dispatch(updateOverviewState({ chatSession: res?.data }))
    }
  )
}

export const getWordCloudData = (data) => (dispatch, getState) => {
  fetchDataAndProceed(
    { dispatch, getState },
    {
      url: GET_TENANT_WORD_CLOUD_STATS,
      method: METHOD_TYPES.GET,
      loader: false,
      data,
    },
    (err, res) => {
      if (res && !err) dispatch(updateOverviewState({ wordCloudData: res?.data }))
    }
  )
}

//------------------------BOT APPEARANCE ----------------------

export const getBotAppearance = createAsyncThunk(
  "tenant/getBotAppearance",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_BOT_APPEARANCE.replace(":tenantId", data.tenantId),
        data,
      },
      helpers
    )
)

export const updateBotAppearance = createAsyncThunk(
  "tenant/updateBotAppearance",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: UPDATE_BOT_APPEARANCE.replace(":tenantId", data.tenantId),
        method: METHOD_TYPES.PUT,
        data: data.payload,
      },
      helpers
    )
)

export const getRouteBasedNotificationList = createAsyncThunk(
  "tenant/getRouteBasedNotificationList",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_ROUTE_BASED_NOTIFICATION_LIST,
        data,
      },
      helpers
    )
)

export const createRouteBasedNotification = createAsyncThunk(
  "tenant/createRouteBasedNotification",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: CREATE_ROUTE_BASED_NOTIFICATION.replace(
          ":tenantId",
          data.tenantId
        ),
        method: METHOD_TYPES.POST,
        data: data.payload,
      },
      helpers
    )
)

export const updateRouteBasedRoutification = createAsyncThunk(
  "tenant/updateRouteBasedRoutification",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: UPDATE_ROUTE_BASED_NOTIFICATION.replaceAll(
          ":tenantId",
          data.tenantId
        ),
        method: METHOD_TYPES.PUT,
        data: data.payload,
      },
      helpers
    )
)

export const deleteRouteBasedNotification = createAsyncThunk(
  "tenant/deleteRouteBasedNotification",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: DELETE_ROUTE_BASED_NOTIFICATION.replaceAll(
          ":tenantId",
          data.tenantId
        ).replace(":id", data.id),
        method: METHOD_TYPES.DELETE,
      },
      helpers
    )
)

export const addMembersAndInvitation = (data, cb) => (dispatch, getState) => {
  fetchDataAndProceed(
    { dispatch, getState },
    {
      url: ADD_MEMBERS,
      method: METHOD_TYPES.POST,
      data,
      loader: false,
      modalLoader: true,
    },
    (err, res) => {
      if (!err && res && res.data) if (cb) cb(res)
    }
  )
}

export const getMembers = (data) => (dispatch, getState) => {
  fetchDataAndProceed(
    { dispatch, getState },
    {
      url: GET_MEMBERS,
      data,
      loader: false,
    },
    (err, res) => {
      if (!err && res && res.data) {
        dispatch(updateOverviewState({ memberList: res.data }))
      }
    }
  )
}

export const deleteMembers = (id, tenantId, userId, cb) => (dispatch, getState) => {
  fetchDataAndProceed(
    { dispatch, getState },
    {
      url: DELETE_MEMBERS
        .replace(":memberId", id)
        .replace(":tenantId", tenantId)
        .replace(":userId", userId),
      method: METHOD_TYPES.DELETE,
      loader: false,
    },
    (err, res) => {
      if (!err && res && res.data) if (cb) cb(res)
    }
  )
}

export const updateMembers = (memberId, tenantId, data, cb) => (dispatch, getState) => {
  fetchDataAndProceed(
    { dispatch, getState },
    {
      url: UPDATE_MEMBERS.replace(":memberId", memberId).replace(":tenantId", tenantId),
      loader: false,
      data,
      method: METHOD_TYPES.PUT
    },
    (err, res) => {
      if (!err && res && res.data) if (cb) cb(res)
    }
  )
}

export const updateInvitations = (id, tenantId, data, cb) => (dispatch, getState) => {
  fetchDataAndProceed(
    { dispatch, getState },
    {
      url: UPDATE_INVITATION.replace(":inviteId", id).replace(":tenantId", tenantId),
      data,
      loader: false,
      method: METHOD_TYPES.PUT
    },
    (err, res) => {
      if (!err && res && res.data) if (cb) cb()
    }
  )
}

export const transferTenant = (tenantId, data, cb) => (dispatch, getState) => {
  fetchDataAndProceed(
    { dispatch, getState },
    {
      url: `${TRANSFER_TENANT.replace(":tenantId", tenantId)}`,
      data:{
        email: data,
        tenantId: tenantId
      },
      method: METHOD_TYPES.POST,
    },
    (err, res) => {
      if (!err && res && res.data) if (cb) cb()
    }
  )
}

export const inviteMembers = (tenantId, data, cb) => (dispatch, getState) => {
  fetchDataAndProceed(
    { dispatch, getState },
    {
      url: INVITE_MEMBERS.replace(":tenantId", tenantId),
      method: METHOD_TYPES.POST,
      data: data,
    },
    (err, res) => {
      if (!err && res?.data) {
        if (cb) cb()
      }
    }
  )
}

export const LiveChatEnabled = createAsyncThunk(
  "tenant/LiveChatEnabled",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: LIVE_CHAT_ENABLED,
        method: METHOD_TYPES.POST,
        data,
      },
      helpers
    )
)

export const getTenantQuota = createAsyncThunk(
  "tenant/getTenantQuota",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_TENANT_QUOTA.replace(":tenantId", data.tenantId),
        data,
        loader: false,
      },
      helpers
    )
)

export const updateTenantQuota = createAsyncThunk(
  "tenant/updateTenantQuota",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: UPDATE_TENANT_QUOTA.replace(":tenantId", data.tenantId),
        data,
        method: METHOD_TYPES.PUT,
        loaderText: "Updating",
      },
      helpers
    )
)

export const getLiveChatConfigurations = createAsyncThunk(
  "tenant/getLiveChatConfigurations",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: LIVE_CHAT_CONFIG,
        baseURL: process.env.REACT_APP_BASE_SOCKET_URL,
        data,
        loader: false,
      },
      helpers
    )
)

export const updateLiveChatConfigurations = createAsyncThunk(
  "tenant/updateLiveChatConfigurations",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: LIVE_CHAT_CONFIG,
        baseURL: process.env.REACT_APP_BASE_SOCKET_URL,
        method: METHOD_TYPES.POST,
        data,
        loader: false,
      },
      helpers
    )
)
export const getQuotaList = createAsyncThunk(
  "tenant/getQuotaList",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_QUOTA_LIST,
        data,
      },
      helpers
    )
)

export const validateBYOK = createAsyncThunk(
  "tenant/validateBYOK",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: VALIDATE_BYOK,
        method: METHOD_TYPES.POST,
        data,
      },
      helpers
    )
)

export const getInvitedMemberList = createAsyncThunk(
  "tenant/getInvitedMemberList",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: LIST_INVITED_MEMBER,
        method: METHOD_TYPES.GET,
        data,
        loader: false
      },
      helpers
    )
)

export const deleteInvitedMember = createAsyncThunk(
  "tenant/deleteInvitedMember",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: DELETE_INVITED_MEMBER
          .replace(":id", data.id)
          .replace(":tenantId", data.tenantId),
        method: METHOD_TYPES.DELETE,
        data,
        loader: false
      },
      helpers
    )
)

export const getTenantStoreData = createAsyncThunk(
  "tenant/getTenantStoreData",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_TENANT_STORE_DATA,
        method: METHOD_TYPES.GET,
        data,
      },
      helpers
    )
)

export const integrateShopify = createAsyncThunk(
  "tenant/integrateShopify",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: INTEGRATE_SHOPIFY,
        data,
        method: METHOD_TYPES.POST,
        loaderText: "Integrating your e-commerce store"
      },
      helpers
    )
)

export const integrateWooCommerce = createAsyncThunk(
  "tenant/integrateWooCommerce",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: INTEGRATE_WO_COMMERCE,
        method: METHOD_TYPES.POST,
        data,
        loaderText: "Integrating your e-commerce store"
      },
      helpers
    )
)

export const integrateCatalog = createAsyncThunk(
  "tenant/integrateCatalog",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: INTEGRATE_CATALOG,
        method: METHOD_TYPES.POST,
        data,
        loaderText: "Integrating your e-commerce store"
      },
      helpers
    )
)

export const wooCommerceVerify = createAsyncThunk(
  "tenant/wooCommerceVerify",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: WOO_COMMERCE_VERIFY,
        data,
        loader: true,
        loaderText: "Verifying Store Details"
      },
      helpers
    )
)

export const shopifyVerify = createAsyncThunk(
  "tenant/shopifyVerify",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: SHOPIFY_VERIFY,
        data,
        loader: true,
        loaderText: "Verifying Store Details"
      },
      helpers
    )
)

export const getEcommerceSyncInfo = createAsyncThunk(
  "tenant/getEcommerceSyncInfo",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_SYNC_INFO,
        data,
        loader: false
      },
      helpers
    )
)

export const getTenantResourceUrlStatus = createAsyncThunk(
  "tenant/getTenantResourceUrlStatus",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_TENANT_RESOURCE_URL_STATUS,
        data,
      },
      helpers
    )
)

export const getAllEComProducts = createAsyncThunk(
  "tenant/getAllEComProducts",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_ALL_ECOM_PRODUCTS,
        data,
        loaderText: "Fetching Products"
      },
      helpers
    )
)

export const updateDynamicLeadStatus = createAsyncThunk(
  "leadCapture/updateDynamicLeadStatus",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: UPDATE_DYNAMIC_LEAD_STATUS,
        data,
        method: METHOD_TYPES.PUT
      },
      helpers
    )
)

export const getDynamicFeedbackFormTemplate = createAsyncThunk(
  "tenant/getDynamicFeedbackFormTemplate",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_DYNAMIC_FEEDBACK_FORM_TEMPLATE,
        data,
        loader: false
      },
      helpers
    )
)

export const postFeedbackFormData = createAsyncThunk(
  "tenant/postFeedbackFormData",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: POST_FEEDBACK_FORM_DATA,
        data,
        method: METHOD_TYPES.PUT,
        loader: false,
      },
      helpers
    )
)

export const getEcommProductStatus = createAsyncThunk(
  "tenant/getEcommProductStatus",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_ECOM_PRODUCT_STATUS,
        data,
      },
      helpers
    )
)

export const uploadMessageMediaImg = createAsyncThunk("workspaceSettings/uploadMessageMediaImg", async (data = {}, helpers) => {
  return fetchDataForToolkit({
      url: UPLOAD_MESSAGE_MEDIA_IMAGE.replaceAll(":tenantId", data.tenantId),
      method: "POST",
      data: data.payload,
      loader: false,
      contentType: "multipart/form-data"
  }, helpers)
});

export const { updateOverviewState, updateTenantInfoState } =
  overviewSlice.actions
export default overviewSlice.reducer
